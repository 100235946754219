import React from 'react';
import PropTypes from 'prop-types';
import {
        VictoryChart,
        VictoryGroup,
        VictoryBar,
        VictoryAxis,
        VictoryTheme
} from 'victory';

const BarGraph = ({series, title, subtitle, barColor}) => {
    const renderTitle = () => {
        if (!title) {
            return null;
        }

        return <h4>{title}</h4>
    }

    const renderSubtitle = () => {
        if (!subtitle) {
            return null;
        }

        return <h3>{subtitle}</h3>
    }

    const renderSeries = () => {
        return null;
    }

    let max = 0;
    for (let i = 0; i < series.length; i++) {
        if (series[i].y > max) {
            max = series[i].y; 
        }
    }

    max = Math.ceil(max / 10) * 10;

    return (
        <div className="bar-chart">
            {renderTitle()}
            <div className="chart">
                <VictoryChart
                    width={420}
                    height={500}
                    domain={{ y: [0, max] }}
                    domainPadding={{ x: 50, y: [0, 20] }}
                    responsize={false}
                >
                    <VictoryBar
                        data={series}
                        labels={(d) => `${d.y}%`}
                        cornerRadius={5}
                        style={{
                            data: {
                                fill: '#e05702',
                            },
                        }}
                        animate={{
                            duration: 500,
                            easing: 'bounceIn',
                        }}
                    />
                    <VictoryAxis
                        style={{
                            tickLabels: { fontSize: 10, fontWeight: 'bold', fill: "#000" },
                            axis: { stroke: 'none' },
                        }}
                    />
                </VictoryChart>
            </div>
            {renderSubtitle()}
        </div>
    );
};

BarGraph.propTypes = {
    series: PropTypes.array.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    barColor: PropTypes.string.isRequired,
};

BarGraph.defaultProps = {
    barColor: '#e05702', 
};

export default BarGraph;
