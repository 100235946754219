import React from 'react';
import PropTypes from 'prop-types';
import { VictoryChart, VictoryGroup, VictoryAxis, VictoryArea } from 'victory';

const LineGraph = ({series, title, colorScale, header, headerColors, max}) => {
    const renderTitle = () => {
        if (!title) {
            return null;
        }

        return <h4>{title}</h4>;
    };

    const renderHeader = () => {
        if (!header) {
            return null;
        }

        return (
            <header>
                {
                    header.map((item, idx) => {
                        const color = idx < headerColors.length ? headerColors[idx] : null;

                        return (
                            <h2 key={`line-graph-h2-${idx}`} className={color ? color : ''}>{item}</h2>
                        )
                    })
                }
            </header>
        );
    };

    const renderGradients = () => {
        return (
            <div className="line-graph-gradients">
                {
                    colorScale.map((color, idx) => {
                        return (
                            <svg key={`gradient-${idx}`} style={{ height: 0 }}>
                                <defs>
                                    <linearGradient
                                        id={`gradient-${idx}`}
                                        gradientTransform="rotate(90)"
                                    >
                                        <stop
                                            offset="0%"
                                            stopColor={color}
                                            stopOpacity="0.8"
                                        />
                                        <stop
                                            offset="100%"
                                            stopColor={color}
                                            stopOpacity="0.39"
                                        />
                                    </linearGradient>
                                </defs>
                            </svg>
                        );
                    })
                }
            </div>
        );
    };

    const renderSeries = () => {
        if (!series || series.length <= 0) {
            return null;
        }

        const gradientScale = colorScale.map((color, idx) => {
            return 'url(#gradient-' + idx + ')';
        });

        return (
            <VictoryGroup>
                {
                    series.map((item, idx) => {
                        return (
                            <VictoryArea
                                key={`area_${idx}`}
                                interpolation="natural"
                                data={item}
                                style={{
                                    data: {
                                        fill: gradientScale[idx],
                                    }
                                }}
                            />
                        );
                    })
                }
            </VictoryGroup>
        );
    };


    return (
        <div className="line-graph">
            {renderTitle()}
            {renderHeader()}
            {renderGradients()}
            <VictoryChart
                domain={{ y: [0, max] }}
                domainPadding={{ x: 50, y: [0, 20] }}
            >
                {renderSeries()}
                <VictoryAxis
                    dependentAxis
                    style={{
                        tickLabels: {
                            fontSize: 8,
                            fontWeight: 'bold',
                            fill: '#3d3d3d',
                        },
                    }}
                />
                <VictoryAxis
                    style={{
                        tickLabels: {
                            fontSize: 8,
                            fontWeight: 'bold',
                            fill: '#3d3d3d',
                        },
                    }}
                />
            </VictoryChart>
        </div>
    );
};

LineGraph.propTypes = {
    series: PropTypes.array.isRequired,
    colorScale: PropTypes.array.isRequired,
    headerColors: PropTypes.array.isRequired,
    title: PropTypes.string,
    max: PropTypes.number,
    footer: PropTypes.array,
};

LineGraph.defaultProps = {
    max: 300,
    colorScale: ['#7ca003', '#002889', '#e05702'],
    headerColors: ['green', 'blue', 'orange'],
};

export default LineGraph;
