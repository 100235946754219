import '@babel/polyfill';
import React from 'react';
import { render } from 'react-dom';

import MultibarGraph from './components/MultibarGraph';
import BarGraph from './components/BarGraph';
import DonutGraph from './components/DonutGraph';
import ImpactNumber from './components/ImpactNumber';
import ImpactNumberGraph from './components/ImpactNumberGraph';
import LineGraph from './components/LineGraph';
import { equal } from 'assert';

document.addEventListener('DOMContentLoaded', function () {
    prepare_map();
    prepare_tooltips();
    prepare_progress_bars();
    prepare_demo_charts();
    prepare_animations();
    prepare_programs();
});

function prepare_programs() {
    for (let i = 0; i < 16; i++) {
        $('.program-list').append('<a class="program-link" href="javascript:void(0)" data-toggle="modal" data-target=".program-info-modal">Program</a>');
        $('.county-list').append('<a class="county-link" href="javascript:void(0)" data-toggle="modal" data-target=".county-info-modal">County</a>');
    }
}

function prepare_counters() {
    $('.counter').each(function() {
        let $this = $(this);
        const countTo = $this.attr('data-count');

        $({ countNum: $this.text()}).animate({
            countNum: countTo
        },

        {
            duration: 3500,
            easing: 'linear',
            step: function() {
                $this.text(Math.floor(this.countNum));
            },
            complete: function() {
                $this.text(this.countNum);
            }
        });
    });
}

function prepare_cards(type) {
    const $cards = $('.' + type + '-card');

    $cards.each(function() {
        $(this).addClass('pop-in');
    });

    $('.' + type + '-card-grp').addClass('pop-in');
}

function prepare_animations() {
    function fadeIn() {
        const windowHeight = window.innerHeight;
        const scrollTopPos = $(document).scrollTop();
        const scrollBottomPos = $(document).scrollTop() + windowHeight;
        const scrollBottomTrigger = scrollBottomPos - (windowHeight / 2);
        const scrollPosSplash = $('.splash').offset().top - 100;
        const scrollPosIntro = $('.intro').offset().top - 100;
        const scrollPosMap = $('.map-section').offset().top - 100;
        const scrollPosTotals = $('.totals').offset().top - 100;
        const scrollPosCards = $('.impact-cards').offset().top - 100;
        const scrollPosTable = $('#table-section').offset().top - 100;
        const scrollPosGraphs = $('#graph-section').offset().top - 100;
        const scrollPosCols = $('.column-card-grp').offset().top - 100;

        // Trigger the animation for the first element
        if (scrollBottomTrigger >= scrollTopPos) {
            $('.splash').addClass('animate');
        }

        // Trigger the animation for the second element
        if (scrollBottomTrigger >= scrollPosIntro) {
            $('.intro').addClass('animate');
        }

        if (scrollBottomTrigger >= scrollPosMap) {
            $('.map-section').addClass('animate');
        }

        if (scrollBottomTrigger >= scrollPosTotals) {
            $('.totals').addClass('animate');
            prepare_counters();
        }

        if (scrollBottomTrigger >= scrollPosCards) {
            prepare_cards('impact');
        }

        if (scrollBottomTrigger >= scrollPosTable) {
            $('#table-section').addClass('animate');
        }

        if (scrollBottomTrigger >= scrollPosGraphs) {
            $('#graph-section').addClass('animate');
        }

        if (scrollBottomTrigger >= scrollPosCols) {
            prepare_cards('column');
        }
    }

    fadeIn();

    $(window).scroll(function() {
        fadeIn();
    });
}

function prepare_map() {
    let map = document.getElementById('county-map');
    const counties = [
        {
            id: 'perry',
            name: 'Perry County',
            programs: [
                'program-one',
                'program-two',
                'program-three',
                'program-four',
                'program-five',
            ],
        },
        {
            id: 'harlan',
            name: 'Harlan County',
            programs: [
                'program-one',
                'program-two',
            ],
        },
    ];

    if (map) {
        $('.county').each(function(idx) {
            let countyId = this.id;
            let county = counties.find(cty => cty.id === countyId);
            let countyTitle = typeof county !== 'undefined' ? county.name : 'County';

            // TODO: add program list in tooltip?
            // county && counties[county].programs.forEach(prg => {
            // countyTitle += '<div class="tooltip-sub-inner icon icon-contact">' + prg + '</div>';
            // });
            countyTitle += '<div class="tooltip-sub-inner icon icon-contact">Programs Available</div>';

            $(this).attr('title', countyTitle);

            // TODO: update program list panel when a county is selected
        });

        $('.program-item').hover(function () {
            let activeCounties = counties.map(cty => {
                if (cty.programs.includes($(this).data('val'))) {
                    return cty.id;
                }
            });
            activeCounties.forEach(cty => {
                $('#' + cty).addClass('active');
            });
        }, function() {
            $('.county.active').removeClass('active');
        });
    }
}

function prepare_progress_bars() {
    $('.progress-bar').each((idx, el) => {
        let width = $(el).width();
        let max = $(el).data('max');
        let innerWidth = width * max / 100;

        $(el).children('.inner').width(innerWidth);
    });
}

function prepare_tooltips() {
    $('[data-toggle=tooltip]').tooltip({
        html: true,
        template: `
            <div class="tooltip" role="tooltip">
                <div class="arrow"></div>
                <div class="tooltip-content">
                    <div class="tooltip-inner"></div>
                </div>
            </div>
        `,
    });

    //Uncomment to force all tooltips to be immediately visible
    //$('[data-toggle=tooltip]').tooltip('show');
}

function prepare_demo_charts() {
    let el = null;

    //Multi-bar Graph
    el = document.querySelectorAll('#multi-bar-graph .chart')[0];
    if (el) {
        const series = [
            [
                {
                    x: "2016",
                    y: 130,
                },
                {
                    x: "2017",
                    y: 100,
                },
                {
                    x: "2018",
                    y: 90,
                },
                {
                    x: "2019",
                    y: 145,
                },
            ],
            [
                {
                    x: "2016",
                    y: 150,
                },
                {
                    x: "2017",
                    y: 175,
                },
                {
                    x: "2018",
                    y: 140,
                },
                {
                    x: "2019",
                    y: 160,
                },
            ],
            [
                {
                    x: "2016",
                    y: 200,
                },
                {
                    x: "2017",
                    y: 60,
                },
                {
                    x: "2018",
                    y: 160,
                },
                {
                    x: "2019",
                    y: 220,
                },
            ]
        ];

        let footer = [
            {
                value: 900,
                label: '# hours',
            },
            {
                value: 105,
                label: '# teachers',
            },
            {
                value: 354,
                label: '# students',
            },
        ];

        render(<MultibarGraph title={'Group Comparisons'} footer={footer} series={series} />, el);
    }

    el = document.querySelectorAll('#bar-graph .chart')[0];
    if (el) {
        const series = [
            {
                x: 'Gear Up',
                y: 20,
            },
            {
                x: 'PN-Knox',
                y: 40,
            },
            {
                x: 'PN-Perry',
                y: 38,
            },
            {
                x: 'NEA',
                y: 53,
            },
        ];

        render(<BarGraph title={'Individual Comparison'} subtitle={'2017 Graduation Rate'} series={series} />, el);
    }

    el = document.querySelectorAll('#donut-graph .chart')[0];
    if (el) {
        const series = [
            {
                x: 'Gear Up',
                y: 15,
            },
            {
                x: 'PN-Knox',
                y: 25,
            },
            {
                x: 'PN-Perry',
                y: 60,
            },
        ];

        let footer = [
            {
                value: '15%',
                label: 'Gear Up',
            },
            {
                value: '60%',
                label: 'PN-Perry',
            },
            {
                value: '25%',
                label: 'PN-Knox',
            },
        ];

        render(<DonutGraph title={'Graduation by Program'} footer={footer} series={series} />, el);
    }

    el = document.querySelectorAll('#impact-number .chart')[0];
    if (el) {
        render(<ImpactNumber title={'Graduation Rate'} max={83} />, el);

    }

    el = document.querySelectorAll('#impact-number-graph .number-card')[0];
    if (el) {
        let series = [{x: 0, y: 2}, {x: 1, y: 2.5}, {x: 2, y: 4}, {x: 3, y: 5}, {x: 4, y: 3}, {x: 5, y: 1}, {x: 6, y: 3}];
        let labels = ['2016', '2017', '2018'];

        render (<ImpactNumberGraph 
            title={'Promise Neighborhood'}
            change={12}
            change_direction={'up'}
            value={452}
            value_title={'High School Graduates'}
            labels={labels}
            series={series}
        />, el);
    }

    el = document.querySelectorAll('#line-graph .chart')[0];
    if (el) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        let series = [];
        let header = ['All Time', 'Last Year', 'This Year'];

        for (let j = 0; j < 3; j++) {
            let row = [];

            for (let i = 0; i < months.length; i++) {
                let month = months[i];
                const val = Math.floor(Math.random() * 200) + 50  

                row.push({
                    x: month,
                    y: val,
                });
            }

            series.push(row);
        }

        render (<LineGraph
            series={series}
            header={header}
            title={'Statistics'}
        />, el);
    }
}
