import React from 'react';
import PropTypes from 'prop-types';
import {
    VictoryChart,
    VictoryGroup,
    VictoryBar,
    VictoryAxis,
    VictoryTheme,
} from 'victory';

const MultibarGraph = ({ series, title, colorScale, footer, footerColors }) => {
    const renderTitle = () => {
        if (!title) {
            return null;
        }

        return <h4>{title}</h4>;
    };

    const renderFooter = () => {
        if (!footer) {
            return null;
        }

        return (
            <footer>
                {
                    footer.map((item, idx) => {
                        const { value, label } = item;
                        const color = idx < footerColors.length ? footerColors[idx] : null;

                        return (
                            <div className="item" key={`idx_${idx}`}>
                                <h1>{value}</h1>
                                <div className={`label ${color ? `${color}` : ''}`}>
                                    {label}
                                </div>
                            </div>
                        );
                    })
                }
            </footer>
        );
    };

    const renderSeries = () => {
        return (
            <VictoryGroup
                offset={20}
                style={{ data: { width: 16 } }}
                colorScale={colorScale}
            >
                {
                    series.map((item, idx) => {
                        return (
                            <VictoryBar
                                data={item}
                                cornerRadius={5}
                                key={`bar_${idx}`}
                                animate={{
                                    duration: 500,
                                }}
                            />
                        );
                    })
                }
            </VictoryGroup>
        );
    };

    return (
        <div className="bar-chart">
            {renderTitle()}
            <div className="chart">
                <VictoryChart
                    width={420}
                    height={452}
                    domain={{ y: [0, 250] }}
                    domainPadding={{ x: 50, y: [0, 20] }}
                    responsize={false}
                >
                    {renderSeries()}
                    <VictoryAxis
                        dependentAxis
                        style={{
                            tickLabels: {
                                fontSize: 10,
                                fontWeight: 'bold',
                                fill: '#000',
                            },
                        }}
                    />
                    <VictoryAxis
                        style={{
                            tickLabels: {
                                fontSize: 10,
                                fontWeight: 'bold',
                                fill: '#000',
                            },
                        }}
                    />
                </VictoryChart>
            </div>
            {renderFooter()}
        </div>
    );
};

MultibarGraph.propTypes = {
    series: PropTypes.array.isRequired,
    colorScale: PropTypes.array.isRequired,
    footerColors: PropTypes.array.isRequired,
    title: PropTypes.string,
    footer: PropTypes.array,
};

MultibarGraph.defaultProps = {
    colorScale: ['#7ca003', '#002889', '#e05702'],
    footerColors: ['green', 'blue', 'orange'],
};

export default MultibarGraph;
