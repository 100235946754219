import React from 'react';
import PropTypes from 'prop-types';
import {
    VictoryChart,
    VictoryPie,    
} from 'victory';

const DonutGraph = ({series, title, colorScale, footer, footerColors }) => {
    const renderTitle = () => {
        if (!title) {
            return null;
        }

        return <h4>{title}</h4>
    }

    const renderFooter = () => {
        if (!footer) {
            return null;
        }

        return (
            <footer>
            {
                footer.map((item, idx) => {
                    const { value, label } = item;
                    const color = idx < footerColors.length ? footerColors[idx] : null;

                    return (
                        <div className="item" key={`idx_${idx}`}>
                            <h1>{value}</h1>
                            <div className={`label ${color ? `${color}` : ''}`}>
                                {label}
                            </div>
                        </div>
                    )
                })
            }
            </footer>
        );
    }

    return (
        <div className="bar-chart donut-chart">
            <div className="chart">
                {renderTitle()}
                <VictoryPie
                    innerRadius={100}
                    labelRadius={120}
                    labels={(d) => ''}
                    colorScale={colorScale}
                    data={series}
                />
            </div>
            {renderFooter()}
        </div>
    );
};

DonutGraph.propTypes = {
    series: PropTypes.array.isRequired,
    title: PropTypes.string,
    colorScale: PropTypes.array.isRequired,
    footerColors: PropTypes.array.isRequired,
};

DonutGraph.defaultProps = {
    colorScale: ["#7ca003", "#002889", "#e05702"],
    footerColors: ['green', 'orange', 'blue'],
};

export default DonutGraph;
