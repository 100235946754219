import React from 'react';
import PropTypes from 'prop-types';
import {
    VictoryChart,
    VictoryAxis,
    VictoryLine,
    VictoryLabel,
    VictoryClipContainer,
} from 'victory';

const ImpactNumberGraph = ({series, labels, change,  value, value_title, title}) => {
    const renderChange = () => {
        if (!change) {
            return null;
        }

        return <span className="change">
            {change}
        </span>
    };

    const renderTitle = () => {
        if (!title) {
            return null;
        }

        return <h4>{title} {renderChange()}</h4>;
    };

    const renderValueTitle = () => {
        if (!value_title) {
            return null;
        }

        return <h4>{value_title}</h4>
    };

    const renderValue = () => {
        if (!value) {
            return null;
        }

        return <div className="value">
            <h1>{value}</h1>
            {renderValueTitle()}
        </div>
    }

    const renderLabels = () => {
        if (!labels) {
            return null;
        }

        return <div className="labels">
        {
            labels.map((label, idx) => {
                return <label key={`label_${idx}`}>
                    {label}
                </label>;
            })
        }
        </div>
    }

    const renderGraph = () => {
        if (!series) {
            return null;
        }

        return <div className="chart">
            {renderLabels()}
            <VictoryChart
                width={700}
                height={200}
                responsize={false}
            >
                <VictoryLine
                    interpolation="natural"
                    groupComponent={
                        <VictoryClipContainer
                            clipPadding={{ top: 5, bottom: 5 }}
                        />
                    }
                    data={series}
                    style={{
                        data: {
                            stroke: '#fff',
                            strokeWidth: 4,
                        },
                    }}
                    animate={{
                        duration: 500,
                        easing: 'sinIn',
                    }}
                />
                <VictoryAxis
                    style={{
                        tickLabels: { fontSize: 0 },
                        axis: { stroke: 'none' },
                    }}
                />
            </VictoryChart>
        </div>
    }

    return (
        <div className="impact-number-graph">
            {renderTitle()}
            {renderValue()}
            {renderGraph()}
        </div>
    );
};

ImpactNumberGraph.propTypes = {
    series: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
    value: PropTypes.number.isRequired,
    value_title: PropTypes.string,
    title: PropTypes.string,
    change: PropTypes.number,
}

export default ImpactNumberGraph;
