import React from 'react';
import PropTypes from 'prop-types';
import {
    VictoryChart,
    VictoryPie,
    VictoryAnimation,
    VictoryLabel,
} from 'victory';

class ImpactNumber extends React.Component {
    constructor() {
        super();

        this.state = {
            percent: 0, data: this.getData(0)
        };
    }

    componentDidMount() {
        const { max } = this.props;

        let percent = 0;
        this.setStateInterval = window.setInterval(() => {
            percent += (Math.random() * 25);
            percent = percent > max ? max : percent;
            this.setState({
                percent, data: this.getData(percent)
            });
        }, 750);
    }

    componentWillUnmount() {
        window.clearInterval(this.setStateInterval);
    }

    getData(percent) {
        return [{ x: 1, y: percent }, { x: 2, y: 100 - percent }];
    }

    renderTitle() {
        const { title } = this.props;

        if (!title) {
            return null;
        }

        return <h3>{title}</h3>
    }

    render() {
        const { max } = this.props;

        return (
            <div className="impact-number">
                <svg viewBox="0 0 400 400" width="100%" height="100%">
                    <VictoryPie
                        standalone={false}
                        animate={{ duration: 500 }}
                        width={400} height={400}
                        data={this.state.data}
                        innerRadius={120}
                        cornerRadius={25}
                        labels={() => null}
                        style={{
                            data: { fill: (d) => {
                                return d.x === 1 ? "#fff" : "transparent";
                            }}
                        }}
                    />
                    <VictoryAnimation duration={500} data={this.state}>
                        {(newProps) => {
                            if (newProps.percent >= max) {
                                window.clearInterval(this.setStateInterval);
                                newProps.percent = max;
                            }
                            return (
                                <VictoryLabel
                                    textAnchor="middle" verticalAnchor="middle"
                                    x={200} y={200}
                                    text={`${Math.round(newProps.percent)}%`}
                                    style={{ fill: '#fff', fontSize: 60 }}
                                />
                            );
                        }}
                    </VictoryAnimation>
                </svg>
                {this.renderTitle()}
            </div>
        );
    }
}

ImpactNumber.propTypes = {
    max: PropTypes.number.isRequired,
    title: PropTypes.string,
}

export default ImpactNumber;
